//  Sidebar with Progress Tracker and Resources links.
// =============================================================================

@import 'includes/_font-icon-variables';

div {
	&#main_wrap div#main.has_sidebar,
	&#main div#primary_widget_outer.has_sidebar {
		background-color: #F5F5F5;
		background-repeat: repeat-y;
		background-position: right top;
		overflow: hidden;
		padding: 0;

		div#primary_widget {
			background-color: #FFFFFF;
			border-right: 1px solid #E0E0E0;
			min-height: 640px;
		}
	}

	&#main_wrap div#main.has_sidebar div#content {
		float: left;
		width: 678px;
		padding-bottom: 20px;
	}

	&#sidebar {
		display: block;
		min-height: 600px;
		padding: 20px;
		width: 240px;
		flex-shrink: 0;

		h3 {
			color: #333333;
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 10px;
		}

		ul {
			&.links {
				font-size: 13px;
				margin-bottom: 35px;
				padding: 5px 12px;

				li {
					display: block;
					padding: 5px 0;

					a {
						display: inline-block;
						line-height: 150%;
						padding: 5px 0 5px 25px;
						position: relative;

						&:before {
							content: '';
							display: block;
							font-family: 'Alkami';
							font-size: 16px;
							font-weight: 400;
							height: 16px;
							left: 0;
							line-height: 16px;
							margin-top: -8px;
							position: absolute;
							top: 50%;
							width: 16px;
						}
					}
				}
			}

			&#resources li a:before {
				content: char-from-code($font-icon-memo);
			}

			&#need_help li a {
				background-image: none; // Only needed until the icon above is refactored into a font icon

				&#help:before {
					content: char-from-code($font-icon-support);
				}
			}

            &#need_help li.contact-link a {
                text-transform: capitalize;

                &::before {
                content: char-from-code($font-icon-envelope);

                }
            }

            &#need_help li.locations-link a {
                text-transform: capitalize;

                &::before{
				content: char-from-code($font-icon-locations);

                }
            }

			&#progress {
				margin-bottom: 40px;
				margin-left: -20px;
				width: 280px;
			}
		}

		a {
			text-decoration: none;
		}

		ul#progress {
			li div {
				background-image: url("https://assets.orb.alkamitech.com/production/assets/global/images/sidebar/progress_sprite.png");
				background-position: 17px -271px;
				background-repeat: no-repeat;
				color: #ABABAB;
				display: block;
				font-weight: bold;
				padding: 17px 20px 17px 50px;
				width: 210px;
			}

			div {
				h5 {
					font-size: 12px;
					font-weight: bold;
					margin: 0;
					padding: 0;
				}

				p {
					font-size: 11px;
					font-weight: normal;
					line-height: 125%;
					margin: 7px 0 0 0;
				}
			}

			li {
				&.last div {
					background-position: 17px -751px;
				}

				&.current {
					background-color: #FFFFFF;
					border-bottom: 1px solid #DDDDDD;
					border-top: 1px solid #DDDDDD;
					-webkit-box-shadow: 0px 1px 0px 0px #FFFFFF;
					box-shadow: 0px 1px 0px 0px #FFFFFF;

					div {
						background-position: 17px -431px;
						color: #333333;
						margin-top: -1px;
						padding-bottom: 16px;
					}

					&.first div {
						background-position: 17px 17px;
					}

					&.last div {
						background-position: 17px -911px;
					}
				}

				&.completed {
					div {
						background-position: 17px -591px;
					}

					&.first div {
						background-position: 17px -113px;
					}
				}
			}
		}
	}
}

/********** Help Links **********/

/*  PROGRESS STEPS 													 				    PROGRESS STEPS
    --------------------------------------------------------------------------------------------------*/

/********** Current Step **********/

/********** Completed Step **********/
